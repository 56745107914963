import React, { useEffect, useRef, useState } from 'react';
import { Container, Box } from '@mui/material';
import './loading/discoball.css';
import { DiscoLights } from './loading/DiscoLights';
import { runDisco } from './loading/disco-utilities';
import { NeonLight } from './loading/NeonLight';
import { DiscoForm } from './loading/DiscoForm';

export const Loading = ({ setDiscoUser }) => {
  const discoBallRef = useRef(null);
  const [discoLoaded, setDiscoLoaded] = useState(false);

  useEffect(() => {
    const discoBall = discoBallRef.current;
    if (!discoLoaded && discoBall) {
      runDisco(discoBall);
      setDiscoLoaded(true);
    }
  }, [discoLoaded]);

  return (
    <Container>
      <Box>
        <div id="discoBallLight"></div>
        <div id="discoBall" ref={discoBallRef}>
          <div id="discoBallMiddle"></div>
        </div>
        <Box sx={{ mt: 32 }}>
          <NeonLight word="My Disco" />
          <DiscoForm
            setDiscoUser={setDiscoUser}
            text="Enter Discogs username, e.g.: zedbenev"
          />
        </Box>
      </Box>
      <DiscoLights />
    </Container>
  );
};
