import { useEffect, useState } from 'react';

import {
  AppBar,
  Box,
  Drawer,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Search from './../../Search';
import { DatePanel } from './../DatePanel';
import { Tools } from './Tools';

export const DiscoAppBar = ({
  showPanel,
  setShowPanel,
  details,
  setDetails,
  setNoMatches,
  isoInstance,
  records,
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false); // State for mobile menu

  const handleMobileToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <AppBar position="fixed">
      <Toolbar sx={{ width: '100%' }}>
        <Typography
          variant="h5"
          component="div"
          sx={{
            fontFamily: 'Warnes',
            flexGrow: 1,
            textTransform: 'uppercase',
            textAlign: 'justify',
          }}
        >
          My Disco
        </Typography>
        <Hidden smDown>
          <Tools
            showPanel={showPanel}
            setShowPanel={setShowPanel}
            details={details}
            setDetails={setDetails}
            mobile={false}
          />
        </Hidden>
        <Hidden smUp>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleMobileToggle}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>

      <Drawer
        anchor="right"
        open={mobileMenuOpen}
        onClose={handleMobileToggle}
        ModalProps={{ keepMounted: true }}
        className="mobile-menu"
      >
        <Tools
          showPanel={showPanel}
          setShowPanel={setShowPanel}
          details={details}
          setDetails={setDetails}
          mobile={true}
        />
      </Drawer>

      <Box className="top-panels">
        {showPanel.search && (
          <Search isoInstance={isoInstance} setNoMatches={setNoMatches} />
        )}

        {showPanel.date && (
          <DatePanel records={records} isoInstance={isoInstance} />
        )}
      </Box>
    </AppBar>
  );
};
