export const runDisco = (ball) => {
  const radius = 80;
  const squareSize = 10;
  const prec = 19.55;
  const fuzzy = 0.001;
  const inc = (Math.PI - fuzzy) / prec;

  for (let t = fuzzy; t < Math.PI; t += inc) {
    const z = radius * Math.cos(t);
    const currentRadius =
      Math.abs(
        radius * Math.cos(0) * Math.sin(t) -
          radius * Math.cos(Math.PI) * Math.sin(t)
      ) / 2.5;
    const circumference = Math.abs(2 * Math.PI * currentRadius);
    const squaresThatFit = Math.floor(circumference / squareSize);
    const angleInc = (Math.PI * 2 - fuzzy) / squaresThatFit;

    for (let i = angleInc / 2 + fuzzy; i < Math.PI * 2; i += angleInc) {
      const square = document.createElement('div');
      const squareTile = document.createElement('div');
      squareTile.style.width = squareSize + 'px';
      squareTile.style.height = squareSize + 'px';
      squareTile.style.transformOrigin = '0 0 0';
      squareTile.style.transform = `rotate(${i}rad) rotateY(${t}rad)`;
      squareTile.style.backgroundColor =
        (t > 1.3 && t < 1.9) || (t < -1.3 && t > -1.9)
          ? randomColor('bright')
          : randomColor('any');
      square.appendChild(squareTile);
      square.className = 'square';
      squareTile.style.animation = 'reflect 2s linear infinite';
      squareTile.style.animationDelay = `${randomNumber(0, 20) / 10}s`;
      squareTile.style.backfaceVisibility = 'hidden';
      const x = radius * Math.cos(i) * Math.sin(t);
      const y = radius * Math.sin(i) * Math.sin(t);
      square.style.transform = `translateX(${Math.ceil(
        x
      )}px) translateY(${y}px) translateZ(${z}px)`;
      ball.appendChild(square);
    }
  }
};

function randomColor(type) {
  const c = type === 'bright' ? randomNumber(130, 255) : randomNumber(110, 190);
  return `rgb(${c}, ${c}, ${c})`;
}

function randomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
