import React, { useEffect, useRef, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import { fetchInitialCollection } from '../utils/api';
import { PlayerPanel } from './PlayerPanel';
import { DiscoLoop } from './DiscoLoop';
import { DiscoAppBar } from './header/DiscoAppBar';
import { Grover } from './Grover';
import { Charts } from './Charts';
import { User } from './User';

import '../styles/disco.css';
import { Pic } from './Pic';
/**
 * @param {Object} props - Component props.
 * @param {string} props.discoUser - The user's Discogs username.
 * @param {Function} props.setDiscoUser - Setter function for the user's Discogs username.
 * @returns {JSX.Element} - The Disco component.
 */
const Disco = ({ discoUser, setDiscoUser }) => {
  const [loading, setLoading] = useState(true);
  const [discoresults, setDisco] = useState([]);
  const [showPanel, setShowPanel] = useState({
    user: false,
    search: false,
    details: false,
    date: false,
    play: false,
    charts: false,
    pic: false,
  });

  const isotopeContainer = useRef(null);
  const [isoReady, setIsoReady] = useState(false);
  const [isoInstance, setIsoInstance] = useState(null);
  const [noMatches, setNoMatches] = useState(null);
  const [records, setRecords] = useState(discoresults || []);
  const [currentId, setCurrentId] = useState(null);
  const [details, setDetails] = useState(false);
  const [ytLinks, setYtLinks] = useState([]);

  // Fetch initial collection from discogs and set to local storage
  // reset user panel in case it was set from that
  useEffect(() => {
    setLoading(true);
    setShowPanel({ ...showPanel, user: false });
    fetchInitialCollection(setDisco, setRecords, setLoading, discoUser);
  }, [discoUser]);

  // Update the layout when details are revealed
  useEffect(() => {
    if (isoInstance) {
      isoInstance.layout();
    }
  }, [details]);

  // Run imagesloaded and then set the isotope container
  useEffect(() => {
    if (isotopeContainer.current) {
      imagesLoaded(isotopeContainer.current, function () {
        const iso = new Isotope(isotopeContainer.current);
        setIsoInstance(iso);
        setIsoReady(true);
        return () => {
          iso.destroy(); // Clean up Isotope instance when the component unmounts
        };
      });
    }
  }, [isotopeContainer.current]);

  if (loading) {
    return <Grover />;
  }

  return (
    <Container disableGutters={true} sx={{ mt: 10 }}>
      <DiscoAppBar
        showPanel={showPanel}
        setShowPanel={setShowPanel}
        details={details}
        setDetails={setDetails}
        setNoMatches={setNoMatches}
        isoInstance={isoInstance}
        records={records}
      />

      {showPanel.play && ytLinks && (
        <PlayerPanel
          showPanel={showPanel}
          setShowPanel={setShowPanel}
          currentId={currentId}
          ytLinks={ytLinks}
        />
      )}

      {showPanel.charts && records && (
        <Charts
          records={records}
          showPanel={showPanel}
          setShowPanel={setShowPanel}
        />
      )}
      {showPanel.user && (
        <User
          discoUser={discoUser}
          setDiscoUser={setDiscoUser}
          showPanel={showPanel}
          setShowPanel={setShowPanel}
        />
      )}
      {showPanel.pic && (
        <Pic
          records={records}
          showPanel={showPanel}
          setShowPanel={setShowPanel}
        />
      )}
      <Box>
        <div
          className={`isotope-container ${isoReady ? 'loaded' : ''}`}
          style={{
            top: `${
              showPanel.search && showPanel.date
                ? 200
                : showPanel.search
                ? 100
                : showPanel.date
                ? 100
                : 0
            }px`,
          }}
          ref={isotopeContainer}
        >
          <DiscoLoop
            records={records}
            playDrawer={showPanel.play}
            setCurrentId={setCurrentId}
            details={details}
            ytLinks={ytLinks}
            setYtLinks={setYtLinks}
          />
          {noMatches && (
            <Typography variant="p" color="error">
              No Matches
            </Typography>
          )}
        </div>
      </Box>
    </Container>
  );
};

export default Disco;
