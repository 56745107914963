import { Disc } from './DIsc';

/**
 * Functional component for rendering a list of records.
 *
 * @param {Object} props - Component props.
 * @param {Array} props.records - The list of records to render.
 * @param {boolean} props.playDrawer - Flag indicating whether the play drawer is open.
 * @param {Function} props.setCurrentId - Setter function for the current ID.
 * @param {boolean} props.details - Flag indicating whether to show details.
 * @returns {JSX.Element} - The DiscoLoop component.
 */
export const DiscoLoop = ({
  records,
  playDrawer,
  setCurrentId,
  details,
  ytLinks,
  setYtLinks,
}) => {
  if (!records || records.length === 0) {
    // TODO : Render a message if no records are available
    return <div>No records available.</div>;
  }

  return (
    <>
      {records.map((r, index) => {
        return (
          <Disc
            key={index}
            discinfo={r.basic_information}
            localImage={r.localImage}
            playDrawer={playDrawer}
            setCurrentId={setCurrentId}
            details={details}
            ytLinks={ytLinks}
            setYtLinks={setYtLinks}
          />
        );
      })}
    </>
  );
};
