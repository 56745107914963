import { Box, Modal } from '@mui/material';
import { useEffect, useState } from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
export const Charts = ({ records, showPanel, setShowPanel }) => {
  const [open, setOpen] = useState(true);
  const [pieChartData, setPieChartData] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setShowPanel({ ...showPanel, charts: false });
    setOpen(false);
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    const makeChart = () => {
      console.log(records);

      const genreCounts = {};
      // Iterate over each album to count genres
      if (Array.isArray(records)) {
        records.forEach((album) => {
          album.basic_information.genres.forEach((genre) => {
            genreCounts[genre] = (genreCounts[genre] || 0) + 1;
          });
        });
      }
      const labels = Object.keys(genreCounts);
      const data = labels.map((label) => ({
        value: genreCounts[label],
        label: label,
      }));

      return data;
    };
    const chartData = makeChart();
    setPieChartData(chartData);
  }, [records]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <PieChart
          series={[
            {
              data: pieChartData,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            },
          ]}
          height={450}
        />
      </Box>
    </Modal>
  );
};
