import { useEffect, useState } from 'react';
import { Drawer, Typography, Box } from '@mui/material';

import ReactPlayer from 'react-player';

// If you are using pip you may want to use stopOnUnmount={false} to continue playing in picture-in-picture mode even after ReactPlayer unmounts
const VideoPlayer = ({ ytLinks }) => {
  const [loading, setLoading] = useState(true);
  const [key, setKey] = useState(0); // Key to reset the ReactPlayer component
  useEffect(() => {
    if (ytLinks) {
      setLoading(true);
      setKey((prevKey) => prevKey + 1);
      console.log('ytLinks: ', ytLinks);
      // setVideoUrl(ytLinks);
    }
  }, [ytLinks]);

  const handleReady = () => {
    setLoading(false);
  };

  const handleError = (error) => {
    setLoading(false);
    console.log('ERROR FROM VIDEO PLAYER', error);
  };

  return (
    <Box>
      {loading && <Typography variant="p">Loading...</Typography>}
      <ReactPlayer
        key={key}
        url={ytLinks}
        controls={true}
        light={false}
        pip={false}
        stopOnUnmount={true}
        width="100%"
        config={{
          youtube: {
            playerVars: {
              playlisttitle: 'Your Playlist Title Here',
            },
          },
        }}
        onReady={handleReady}
        onError={handleError}
      />
    </Box>
  );
};

export const PlayerPanel = ({
  showPanel,
  setShowPanel,
  currentId,
  ytLinks,
}) => {
  useEffect(() => {
    if (ytLinks) {
      setShowPanel({ ...showPanel, play: true });
    }
  }, [ytLinks]);

  return (
    <Drawer
      sx={{
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="bottom"
      open={showPanel.play}
      onClose={() => {
        setShowPanel((prevShowPanel) => ({ ...prevShowPanel, play: false }));
      }}
      hideBackdrop={true}
    >
      <Box>
        <Typography variant="h5">Listen</Typography>
        <Typography variant="p">Select a record to get started.</Typography>
        {ytLinks ? (
          <VideoPlayer ytLinks={ytLinks} />
        ) : (
          <Typography variant="p">No videos to display</Typography>
        )}
      </Box>
    </Drawer>
  );
};
