import React, { useState } from 'react';

import { TextField, Button } from '@mui/material';

export const DiscoForm = ({ setDiscoUser, text }) => {
  const [user, setUser] = useState('');
  const handleChange = (e) => {
    console.log(typeof e.target.value);
    setUser(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setDiscoUser(user);
  };

  return (
    <div className="glowing-text-input-container">
      <form onSubmit={handleSubmit}>
        <div
          style={{
            position: 'relative',
            textAlign: 'center',
            width: '350px',
            margin: 'auto',
          }}
        >
          <TextField
            label={text}
            className="text-input"
            variant="outlined"
            onChange={handleChange}
            sx={{
              width: '100%',
            }}
          />
          <Button
            variant="contained"
            type="submit"
            className="goButton"
            sx={{
              fontSize: 16,
              width: '350px',
            }}
          >
            GO
          </Button>
        </div>
      </form>
    </div>
  );
};
