import {
  Box,
  Button,
  Drawer,
  Modal,
  TextField,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { NeonLight } from './loading/NeonLight';
import { DiscoForm } from './loading/DiscoForm';
import axios from 'axios';
import { FullscreenExit, HelpOutline } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Grover } from './Grover';

const DimensionButtons = ({ dimensions, setDimensions }) => {
  const handleDimensionChange = (index) => {
    const newDimensions = dimensions.map((dim, i) =>
      i === index ? { ...dim, enabled: true } : { ...dim, enabled: false }
    );
    setDimensions(newDimensions);
  };

  return (
    <Box className="dimension-box">
      {dimensions.map((dimension, index) => (
        <Button
          key={index}
          variant={dimension.enabled ? 'contained' : 'outlined'}
          onClick={() => handleDimensionChange(index)}
        >
          {dimension.label}: {dimension.width}x{dimension.height}
        </Button>
      ))}
    </Box>
  );
};

export const Pic = ({ records, showPanel, setShowPanel }) => {
  const [open, setOpen] = useState(true);
  const [albumCovers, setAlbumCovers] = useState([]);
  const [generatedWallpaperUrl, setGeneratedWallpaperUrl] = useState('');
  const [picsLoading, setPicsLoading] = useState(false);
  const [dimensions, setDimensions] = useState([
    { label: 'Desktop', width: 1920, height: 1080, enabled: true },
    { label: 'Phone', width: 640, height: 960, enabled: false },
    { label: 'Social Header', width: 1200, height: 628, enabled: false },
  ]);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setShowPanel({ ...showPanel, pic: false });
    setOpen(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    maxWidth: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const getImages = () => {
    const root = process.env.REACT_APP_DISCO_API;
    const newImagesArray = records.slice(0, 10).map((r, index) => {
      return root + r.localImage;
    });

    setAlbumCovers(newImagesArray);
  };

  useEffect(() => {
    getImages();
  }, []);

  const generateWallpaper = async () => {
    try {
      setGeneratedWallpaperUrl('');
      setPicsLoading(true);

      // Get the currently enabled dimension
      const enabledDimension = dimensions.find((dim) => dim.enabled);
      const { width, height } = enabledDimension;

      const images = document.querySelectorAll(
        '.record-container:not([style*="display: none"]) img'
      );

      const imageUrls = Array.from(images).map((img) => img.src);
      const response = await axios.post(
        //  'http://localhost:3001/generate-wallpaper',
        process.env.REACT_APP_WALLPAPER,

        { images: imageUrls, width: width, height: height },
        {
          timeout: 30000,
          responseType: 'blob',
        }
      );

      // Create a Blob object from the response data
      const imageBlob = new Blob([response.data], { type: 'image/jpeg' });

      // Create a Blob URL from the Blob object
      const imageUrl = URL.createObjectURL(imageBlob);

      setPicsLoading(false);

      // Set the Blob URL as the background image of a div
      setGeneratedWallpaperUrl(imageUrl); // Assuming you have a state variable to store the URL
    } catch (error) {
      console.error('Error generating wallpaper:', error);
    }
  };

  // useEffect(() => {
  //   generateWallpaper();
  // }, []);

  const downloadWallpaper = () => {
    if (!generatedWallpaperUrl) {
      console.error('No wallpaper to download.');
      return;
    }

    // Create an anchor element
    const a = document.createElement('a');
    a.href = generatedWallpaperUrl;
    a.download = 'generated-wallpaper.jpg'; // Set the filename for the downloaded file

    // Append the anchor element to the body
    document.body.appendChild(a);

    // Click the anchor element to trigger the download
    a.click();
    // Remove the anchor element from the body
    document.body.removeChild(a);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <div className="close-container">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        {!picsLoading && (
          <>
            <div className="wallpaper-header">
              <NeonLight word="Wallpaper" />
              <Tooltip
                title='Use the filters on the main screen to get your selection, choose your dimensions, and then click "Generate Wallpaper"'
                arrow
              >
                <IconButton>
                  <HelpOutline />
                </IconButton>
              </Tooltip>
            </div>
            <DimensionButtons
              dimensions={dimensions}
              setDimensions={setDimensions}
            />
            <Box className="button-container">
              <Button
                onClick={generateWallpaper}
                variant="contained"
                className="goButton"
              >
                Generate {generatedWallpaperUrl && ' New '} Wallpaper
              </Button>
            </Box>
          </>
        )}

        {generatedWallpaperUrl && (
          <Box className="wallpaper-container">
            <img src={generatedWallpaperUrl} alt="Generated Wallpaper" />
            <Box className="download-button">
              <Button variant="contained" onClick={downloadWallpaper}>
                Download Wallpaper
              </Button>
            </Box>
          </Box>
        )}
        {picsLoading && !generatedWallpaperUrl && <Grover />}
      </Box>
    </Modal>
  );
};
