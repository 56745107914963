import { useEffect, useState } from 'react';
import { Box, Button, Chip, Divider, Modal, Typography } from '@mui/material';
import { fetchAdditionalData } from '../utils/api';
import LaunchIcon from '@mui/icons-material/Launch';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { AlbumSides } from './AlbumSides';
const { sanitizeAndConcatenate } = require('../utils/stringUtils');

/**
 * Component to display information about a record from Discogs.
 *
 * @param {Object} discinfo - Information about the record.
 * @param {string} localImage - Local path to the record image.
 * @param {boolean} playDrawer - Whether to display a play icon.
 * @param {Function} setCurrentId - Function to set the current record ID.
 * @param {boolean} details - Whether to display additional details.
 * @param {Function} setYtLinks - Function to set YouTube links.
 * @returns {JSX.Element} - The Disc component JSX.
 */
export const Disc = ({
  discinfo,
  localImage,
  playDrawer,
  setCurrentId,
  details,
  setYtLinks,
}) => {
  const { title, artists } = discinfo;

  const artist = artists && artists.length > 0 ? artists[0].name : null;

  let masterData = discinfo.master_url;
  let resourceData = discinfo?.resource_url;
  let genres = discinfo?.genres;

  // Sanitize strings for CSS classes

  let artClass = artist ? artist.trim().replace(/\s+/g, '-') : null;
  let titleClass = title ? title.trim().replace(/\s+/g, '_') : null;
  let dateClass = discinfo?.year;

  const [additionalData, setAdditionalData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSide, setSelectedSide] = useState('A');

  const handleModalOpen = () => {
    setIsModalOpen(true);
    console.log(additionalData);
  };

  const handleModalClose = () => {
    setAdditionalData(null);
    setIsModalOpen(false);
  };

  const recordData = sanitizeAndConcatenate(artClass, titleClass);
  const imgroot = process.env.REACT_APP_DISCO_API;
  let imgsrc = imgroot + localImage || discinfo.cover_image;

  const recordClick = async (event) => {
    console.log('recordClick');
    const masterUrl = event.currentTarget.dataset.master;
    const resourceUrl = event.currentTarget.dataset.resource;
    const itemId = event.currentTarget.dataset.itemId;
    setCurrentId(itemId);
    if (resourceUrl) {
      const moreData = await fetchAdditionalData(
        masterUrl,
        resourceUrl,
        itemId
      );
      console.log('fetchDone', moreData);
      if (moreData && moreData.youtubeLinks) {
        setYtLinks(moreData.youtubeLinks);
        console.log('SetYTlinks : ', moreData.youtubeLinks);
      } else {
        setYtLinks([]);
        console.log('No YouTube links found!');
      }
    } else {
      console.error('Master URL not found');
    }
  };

  const moreInfo = async (event) => {
    const masterUrl = event.currentTarget.dataset.master;
    const resourceUrl = event.currentTarget.dataset.resource;
    const itemId = String(event.currentTarget.dataset.itemId); // Convert to string
    setCurrentId(itemId);

    // Check if the additional data already exists in local storage
    const storedData = JSON.parse(localStorage.getItem('disco'));
    const existingRelease = storedData.allReleases.find(
      (release) => String(release.id) === itemId
    );

    if (existingRelease && existingRelease.additionalData) {
      console.log('Using additional data from local storage');
      setAdditionalData(existingRelease.additionalData);
      setSelectedSide('A');
      handleModalOpen();
      return;
    }

    if (resourceUrl) {
      setIsLoading(true);

      try {
        const data = await fetchAdditionalData(masterUrl, resourceUrl, itemId);
        console.log(data);
        setAdditionalData(data);
        setSelectedSide('A');
        handleModalOpen();
      } catch (error) {
        console.error('Error fetching additional data:', error.message);
      } finally {
        setIsLoading(false);
      }
    } else {
      console.error('Master URL not found');
    }
  };

  return (
    <div
      className={`record-container isotope-item  year-${dateClass} ${recordData} ${
        details ? 'record-details' : null
      }`}
    >
      <Box className="disc-item">
        <Box
          className="record-cover"
          data-master={masterData}
          data-resource={resourceData}
          data-item-id={discinfo.id}
          onClick={recordClick}
        >
          {playDrawer && (
            <PlayCircleIcon
              sx={{
                position: 'absolute',
                top: '20%',
                left: '28%',
                fontSize: '3.5em',
                cursor: 'pointer',
              }}
            />
          )}
          <img src={imgsrc} alt={title} />
        </Box>

        {details && (
          <Box className="disc-details">
            <Typography variant="h1">{title}</Typography>
            <Divider />
            <Typography variant="h2">{artist}</Typography>
            <Typography variant="h2">Release Date : {dateClass}</Typography>

            <Button
              onClick={moreInfo}
              data-master={masterData}
              data-resource={resourceData}
              data-item-id={discinfo.id}
              variant="text"
              sx={{ p: 0.2, mt: 0.2 }}
            >
              more
              <LaunchIcon />
            </Button>
            <Modal
              open={isModalOpen}
              onClose={handleModalClose}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
            >
              <Box
                sx={{
                  bgcolor: 'background.paper',
                }}
                className="modal-root"
              >
                {isLoading ? (
                  <Typography>Loading...</Typography>
                ) : (
                  <>
                    <Button
                      variant="outlined"
                      onClick={handleModalClose}
                      sx={{ mt: 2, borderRadius: '50%', position: 'absolute' }}
                      className="close-modal"
                    >
                      X
                    </Button>
                    <Box className="more-details-container">
                      <div className="more-album-container">
                        <img src={imgsrc} alt={title} />

                        {additionalData &&
                          additionalData.notes &&
                          additionalData.notes.length > 600 && (
                            <AlbumSides
                              additionalData={additionalData}
                              setSelectedSide={setSelectedSide}
                              selectedSide={selectedSide}
                            />
                          )}
                      </div>
                      <div className="more-details">
                        <Typography
                          id="modal-title"
                          variant="h4"
                          component="h1"
                        >
                          {artist}
                        </Typography>
                        <Typography
                          id="modal-title"
                          variant="h6"
                          component="h2"
                        >
                          {title}
                        </Typography>
                        <Typography id="modal-description" sx={{ mt: 2 }}>
                          {additionalData &&
                            additionalData.notes &&
                            additionalData.notes.length > 0 &&
                            additionalData.notes}
                        </Typography>
                        {additionalData?.lowest_price && (
                          <p className="price">
                            Cheapest sale price (from Discogs): $
                            {additionalData?.lowest_price}
                          </p>
                        )}
                        <Typography
                          id="modal-description"
                          sx={{ mt: 2 }}
                        ></Typography>
                        <Box className="genres">
                          <Typography variant="h6">Genres : </Typography>

                          {genres.map((genre, index) => {
                            return (
                              <Chip
                                key={index}
                                label={genre}
                                sx={{ borderRadius: 2, ml: 1 }}
                              />
                            );
                          })}
                        </Box>

                        {additionalData &&
                          additionalData.notes &&
                          additionalData.notes.length <= 600 && (
                            <AlbumSides
                              additionalData={additionalData}
                              setSelectedSide={setSelectedSide}
                              selectedSide={selectedSide}
                            />
                          )}
                      </div>
                    </Box>
                  </>
                )}
              </Box>
            </Modal>
          </Box>
        )}
      </Box>
    </div>
  );
};
