import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import ClearIcon from '@mui/icons-material/Clear';

const Search = ({ isoInstance, setNoMatches }) => {
  const [searchQuery, setSearchQuery] = useState('');
  useEffect(() => {
    if (!isoInstance) return;
    // fetch all class names and filter items that match, uses debounce delay of 200 ms
    const filterItems = (query) => {
      if (!query) {
        return null; // Show all items if no search query
      } else {
        const regex = new RegExp(query, 'gi');
        const filteredSelectors = isoInstance.items.reduce((acc, item) => {
          const classNames = item.element ? item.element.className : '';
          if (regex.test(classNames)) {
            const selector = `.${classNames.replace(/\s+/g, '.')}`;
            return [...acc, selector];
          } else {
            return acc;
          }
        }, []);

        // provide message if no matches
        setNoMatches(filteredSelectors.length === 0 ? true : false);

        return filteredSelectors.length
          ? filteredSelectors
          : ['.dummy-selector']; // prevent errors if no class name so use dummy
      }
    };

    // replace spaces with hypens to match class
    const formattedQuery = searchQuery.trim().replace(/\s+/g, '-');
    const reArrange = () => {
      isoInstance.arrange({ filter: filterItems(formattedQuery) });
    };

    // Added a delay of 200 seconds before isotope is triggered
    const debouncedReArrange = debounce(reArrange, 200);
    debouncedReArrange();
  }, [isoInstance, searchQuery, setNoMatches]);

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <Box sx={{ p: 2 }} className="search-panel">
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        onChange={handleInputChange}
        value={searchQuery}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  setSearchQuery('');
                }}
              >
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default Search;
