import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';

export const NeonLight = ({ word }) => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Simulate font loading delay
    const fontLoadingDelay = setTimeout(() => {
      setIsVisible(true);
    }, 1000);
    // Clear the timeout to prevent memory leaks
    return () => clearTimeout(fontLoadingDelay);
  }, []);

  return (
    <Typography
      variant="h4"
      className="main-name"
      sx={{
        fontFamily: 'Warnes',
        opacity: isVisible ? 1 : 0,
        visibility: isVisible ? 'visible' : 'hidden',
        fontSize: 64,
      }}
    >
      {word}
    </Typography>
  );
};
