import { Box } from '@mui/material';
import { useEffect } from 'react';

export const AlbumSides = ({
  additionalData,
  setSelectedSide,
  selectedSide,
}) => {
  const handleSideChange = (side) => {
    setSelectedSide(side);
  };

  useEffect(() => {
    console.log(additionalData.tracklist);
  }, []);

  const tracklistToUse =
    additionalData?.sub_tracks || additionalData?.tracklist || [];

  return (
    <Box className="album-sides">
      <div className="side-selector">
        {additionalData &&
          Array.from(
            new Set(
              tracklistToUse
                .filter((track) => track.position && track.position !== '')
                .map((track) => track.position[0])
            )
          ).map((side) => (
            <button
              key={side}
              onClick={() => handleSideChange(side)}
              className={selectedSide === side ? 'active' : ''}
            >
              Side {side}
            </button>
          ))}
      </div>
      <div className="sides">
        <h2>Side {selectedSide}</h2>
        <ul>
          {tracklistToUse
            .filter(
              (track) =>
                track.position && track.position.startsWith(selectedSide)
            )
            .map((track) => (
              <li key={track.title}>
                {track.title}
                {track.duration && ': ' + track.duration}
              </li>
            ))}
        </ul>
      </div>
    </Box>
  );
};
