import { IconButton } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import NotesIcon from '@mui/icons-material/Notes';
import BarChartIcon from '@mui/icons-material/BarChart';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import Face5Icon from '@mui/icons-material/Face5';

export const Tools = ({
  showPanel,
  setShowPanel,
  details,
  setDetails,
  mobile,
}) => {
  const togglePanel = (panel) => {
    if (panel === 'details') {
      setDetails(!details);
    }
    setShowPanel((prevState) => ({
      ...prevState,
      [panel]: !prevState[panel],
    }));
  };

  return (
    <div className="tools">
      <IconButton
        size="large"
        edge="start"
        onClick={() => togglePanel('user')}
        className={showPanel.user ? 'active' : ''}
      >
        <Face5Icon />
      </IconButton>
      <IconButton
        size="large"
        edge="start"
        onClick={() => togglePanel('search')}
        className={showPanel.search ? 'active' : ''}
      >
        <SearchIcon />
      </IconButton>
      <IconButton
        size="large"
        edge="start"
        onClick={() => togglePanel('details')}
        className={showPanel.details ? 'active' : ''}
      >
        <NotesIcon />
      </IconButton>

      <IconButton
        size="large"
        edge="start"
        onClick={() => togglePanel('charts')}
        className={showPanel.charts ? 'active' : ''}
      >
        <BarChartIcon />
      </IconButton>

      <IconButton
        size="large"
        edge="start"
        onClick={() => togglePanel('date')}
        className={showPanel.date ? 'active' : ''}
      >
        <CalendarMonthIcon />
      </IconButton>
      <IconButton
        size="large"
        edge="start"
        onClick={() => togglePanel('play')}
        className={showPanel.play ? 'active' : ''}
      >
        <QueueMusicIcon />
      </IconButton>
      <IconButton
        size="large"
        edge="start"
        onClick={() => togglePanel('pic')}
        className={showPanel.pic ? 'active' : ''}
      >
        <CameraAltIcon />
      </IconButton>
    </div>
  );
};
