import React, { useEffect, useRef, useState } from 'react';
import { Container, Box } from '@mui/material';
import './loading/discoball.css';
import { DiscoLights } from './loading/DiscoLights';
import { runDisco } from './loading/disco-utilities';
import { NeonLight } from './loading/NeonLight';
import { DiscoForm } from './loading/DiscoForm';

export const Grover = () => {
  const [isVisible, setIsVisible] = useState(false);
  const discoBallRef = useRef(null);
  const [discoLoaded, setDiscoLoaded] = useState(false);

  useEffect(() => {
    const discoBall = discoBallRef.current;
    if (!discoLoaded && discoBall) {
      runDisco(discoBall);
      setDiscoLoaded(true);
    }
  }, [discoLoaded]);
  useEffect(() => {
    // Simulate font loading delay
    const fontLoadingDelay = setTimeout(() => {
      setIsVisible(true);
    }, 500);
    // Clear the timeout to prevent memory leaks
    return () => clearTimeout(fontLoadingDelay);
  }, []);

  return (
    <Container>
      <Box>
        <div id="discoBallLight"></div>
        <div id="discoBall" ref={discoBallRef}>
          <div id="discoBallMiddle"></div>
        </div>
        <Box sx={{ p: 32 }}>
          <NeonLight word="Loading" />
        </Box>
        <div className="loading-grover">
          <img src="/graphics/dancing-grover.gif" alt="loading" />
        </div>
      </Box>
      <DiscoLights />
    </Container>
  );
};
