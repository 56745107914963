import { useEffect, useState } from 'react';
import { Box, Chip } from '@mui/material';

export const DatePanel = ({ records, isoInstance }) => {
  const [selectedYears, setSelectedYears] = useState([]);
  const [years, setYears] = useState([]);
  // Fetch years and clean up array
  useEffect(() => {
    // remove dupes
    let uniqueYearsSet = new Set(
      records.map((item) => item.basic_information.year)
    );
    // convert back to array
    let uniqueYearsArray = [...uniqueYearsSet];
    // sort numerically
    let sortedYears = uniqueYearsArray.sort((a, b) => a - b);
    setYears(sortedYears);
  }, [records]);

  const filterByYears = (selectedYears) => {
    if (isoInstance) {
      const filterString =
        selectedYears.length > 0
          ? selectedYears.map((year) => `.year-${year}`).join(', ')
          : '*';

      isoInstance.arrange({ filter: filterString });
    }
  };

  const resetFilters = () => {
    if (isoInstance) {
      isoInstance.arrange({ filter: '*' });
    }
  };

  const handleChipClick = (year) => {
    if (year === 'All') {
      resetFilters();
      setSelectedYears([]);
    } else {
      // Toggle the selection status of the clicked year
      const newSelectedYears = selectedYears.includes(year)
        ? selectedYears.filter((selectedYear) => selectedYear !== year)
        : [...selectedYears, year];

      setSelectedYears(newSelectedYears);

      // Apply filters for the selected years
      filterByYears(newSelectedYears);
    }
  };

  if (!years || years.length === 0) {
    return <>No Year Data Available</>;
  } else {
    return (
      <Box
        className="date-box"
        sx={{
          width: '100%',
          height: '100px',
          overflowX: 'auto',
          display: 'flex',
          flexWrap: 'nowrap', // Prevent wrapping
          gap: '18px',
          p: 1,
          alignItems: 'center',
          justifyContent: 'space-evenly',
        }}
      >
        {years.map((year, index) => {
          return (
            <Chip
              key={index}
              label={year.toString()}
              onClick={() => handleChipClick(year)}
              color={selectedYears.includes(year) ? 'secondary' : 'primary'}
              variant="outlined"
              sx={{
                borderRadius: '50%',
                width: '60px',
                height: '60px',
                minWidth: '60px',
              }}
            />
          );
        })}
        <Chip
          key="reset"
          label="All"
          onClick={() => handleChipClick('All')}
          color="primary"
          variant="outlined"
          sx={{
            borderRadius: '50%',
            width: '60px',
            height: '60px',
            minWidth: '60px',
          }}
        />
      </Box>
    );
  }
};
