/**
 * Sanitizes and concatenates two strings containing the artist and album title for this app
 * it first removes any characters that are not letters, digits, hyphens, or underscores.
 * then replaces the spaces with hyphens so can be used for classNames or data-attributes
 *
 * @param {string} str1 The first string to be sanitized and concatenated.
 * @param {string} str2 The second string to be sanitized and concatenated.
 * @param {string} [separator='-'] The separator to use between the sanitized strings.
 * @returns {string} The sanitized and concatenated string.
 */

export const sanitizeAndConcatenate = (str1, str2, separator = '-') => {
  // Define a regex pattern to match any characters that are not letters, digits, hyphens, or underscores
  const pattern = /[^a-zA-Z0-9-_]/g;

  // Normalize and sanitize the strings
  const normalizedStr1 = str1 ? str1.trim().toLowerCase() : '';
  const normalizedStr2 = str2 ? str2.trim().toLowerCase() : '';
  const sanitizedStr1 = normalizedStr1.replace(pattern, '');
  const sanitizedStr2 = normalizedStr2.replace(pattern, '');

  // Concatenate the sanitized strings with the specified separator
  return `${sanitizedStr1}${separator}${sanitizedStr2}`;
};

export const fetchAndSortYears = (records, setYears) => {
  let uniqueYearsSet = new Set(
    records.map((item) => item.basic_information.year)
  );
  let uniqueYearsArray = [...uniqueYearsSet];
  let sortedYears = uniqueYearsArray.sort((a, b) => a - b);
  setYears(sortedYears);
};
