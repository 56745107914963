import React, { useEffect, useRef, useState } from 'react';

// Light component representing a single moving light
const Light = ({ positionX, positionY }) => {
  return (
    <div
      className="light"
      style={{ transform: `translate(${positionX}px, ${positionY}px)` }}
    />
  );
};

// DiscoLights component responsible for managing and animating multiple lights
export const DiscoLights = () => {
  const [lights, setLights] = useState([]); // State to manage the array of lights
  const lightsRef = useRef([]); // Ref to hold the lights array for efficient updates

  useEffect(() => {
    // Function to generate a random light object
    const generateRandomLight = () => {
      return {
        positionX: Math.random() * window.innerWidth,
        positionY: Math.random() * window.innerHeight,
        angle: Math.random() * 360, // Random angle for light direction
      };
    };

    // Initialize lights when component mounts
    const initialLights = Array.from({ length: 22 }, generateRandomLight);
    setLights(initialLights);
    lightsRef.current = initialLights;

    // Function to animate the lights
    const animateLights = () => {
      const newLights = lightsRef.current.map((light) => {
        // Calculate new position based on angle and current position
        let newPositionX =
          light.positionX + Math.cos((light.angle * Math.PI) / 180);
        let newPositionY =
          light.positionY + Math.sin((light.angle * Math.PI) / 180);

        // Wrap around if the light leaves the screen
        if (newPositionX < 0) {
          newPositionX = window.innerWidth;
        } else if (newPositionX > window.innerWidth) {
          newPositionX = 0;
        }

        if (newPositionY < 0) {
          newPositionY = window.innerHeight;
        } else if (newPositionY > window.innerHeight) {
          newPositionY = 0;
        }

        return {
          ...light,
          positionX: newPositionX,
          positionY: newPositionY,
        };
      });

      // Update state with new positions
      setLights(newLights);
      lightsRef.current = newLights;

      // Request next animation frame
      requestAnimationFrame(animateLights);
    };

    // Start the animation loop
    const animationId = requestAnimationFrame(animateLights);

    // Cleanup function to cancel animation frame on unmount
    return () => cancelAnimationFrame(animationId);
  }, []);

  // Render the lights
  return (
    <div
      id="lights-container"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        pointerEvents: 'none',
        zIndex: -1,
      }}
    >
      {lights.map((light, index) => (
        <Light
          key={index}
          positionX={light.positionX}
          positionY={light.positionY}
        />
      ))}
    </div>
  );
};
