import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import Disco from './components/Disco';
import { Loading } from './components/Loading';
import { useState } from 'react';
import './styles/disco.css';
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
function App() {
  const [discoUser, setDiscoUser] = useState('');
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      {discoUser ? (
        <Disco discoUser={discoUser} setDiscoUser={setDiscoUser} />
      ) : (
        <Loading setDiscoUser={setDiscoUser} />
      )}
    </ThemeProvider>
  );
}

export default App;
