import axios from 'axios';

/**
 * Fetches the initial collection from the Discogs API and updates the local storage and state accordingly.
 *
 * @param {Function} setDisco - Setter function to update the state with the fetched collection.
 * @param {Function} setRecords - Setter function to update the state with the fetched records.
 * @param {Function} setLoading - Setter function to update the loading state.
 * @param {string} discoUser - The user's Discogs username.
 * @returns {Promise<void>} - A Promise that resolves once the initial collection has been fetched and updated.
 */
export const fetchInitialCollection = async (
  setDisco,
  setRecords,
  setLoading,
  discoUser
) => {
  console.log(discoUser);

  // Clear localStorage when discoUser changes
  localStorage.removeItem('disco');
  const apiUrl = process.env.REACT_APP_DISCO_API;

  try {
    const req = await axios.get(apiUrl + discoUser);
    const result = req.data;
    localStorage.setItem('disco', JSON.stringify(result));

    // Combine all releases from the different arrays into one array
    const allReleases = result?.allReleases.flatMap(
      (releaseArray) => releaseArray
    );

    setDisco(JSON.stringify(result));
    setRecords(allReleases || []);
  } catch (error) {
    console.error('Error fetching data from Discogs API:', error.message);
  }

  setLoading(false);
};

/**
 * Fetches additional data for an item from the provided masterUrl and updates local storage with the retrieved data.
 *
 * @see Disc.js
 * @param {string} resourceUrl The URL to fetch additional data - retrieved from attribute data-resource
 * @param {string|number} itemId The ID of the item for which additional data is to be fetchd - from data-item-id
 * @returns {Promise<void>} A Promise that resolves once the additional data is fetched and stored.
 */
export const fetchAdditionalData = async (masterUrl, resourceUrl, itemId) => {
  if (!masterUrl) return;

  try {
    const additionalDataResponse = await axios.get(masterUrl);

    console.log('What additional response???', additionalDataResponse);

    // New object to be appended to the local storage data
    const additionalData = {
      lowest_price: additionalDataResponse.data.lowest_price,
      notes: additionalDataResponse.data.notes,
      num_for_sale: additionalDataResponse.data.num_for_sale,
      tracklist: additionalDataResponse.data.tracklist,
      youtubeLinks: Array.isArray(additionalDataResponse.data.videos)
        ? additionalDataResponse.data.videos.map((video) => video.uri)
        : [],
    };

    console.log('WHAT ADDITONAL DATA ', additionalData);

    // Update the local storage with the modified data
    const storedData = JSON.parse(localStorage.getItem('disco'));

    console.log('STORED DATA ', storedData);
    const updatedReleases = storedData.allReleases.map((release) => {
      if (String(release.id) === itemId) {
        return { ...release, additionalData };
      }

      return release;
    });

    // Update the stored data with the modified releases array
    localStorage.setItem(
      'disco',
      JSON.stringify({ ...storedData, allReleases: updatedReleases })
    );

    console.log('WHAT?? ', additionalData);
    return additionalData;
  } catch (error) {
    console.error(
      `Error fetching additional data from ${masterUrl}:`,
      error.message
    );
    throw error;
  }
};
