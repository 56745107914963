import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { NeonLight } from './loading/NeonLight';
import { DiscoForm } from './loading/DiscoForm';

export const User = ({ discoUser, setDiscoUser, showPanel, setShowPanel }) => {
  const [open, setOpen] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setShowPanel({ ...showPanel, user: false });
    setOpen(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {}, []);
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <NeonLight word={discoUser} />
        <DiscoForm setDiscoUser={setDiscoUser} text="Change User" />
      </Box>
    </Modal>
  );
};
